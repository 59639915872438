











































































































import { ConfirmModal, SvgImage, TailwindTable } from '@/app/components';
import { useAxios, usePagination } from '@/app/composable';
import { ColumnPosition, ColumnType } from '@/app/constants';
import { PencilAltIcon, TrashIcon, UserAddIcon, UserGroupIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { OrganizationsAPI } from '../api';
import DepartmentModal from './DepartmentModal.vue';
import ManageDepartmentUsersModal from './ManageDepartmentUsersModal.vue';

export default defineComponent({
    name: 'OrganisationDepartments',
    components: {
        ConfirmModal,
        SvgImage,
        TailwindTable,
        PencilAltIcon,
        TrashIcon,
        DepartmentModal,
        UserGroupIcon,
        UserAddIcon,
        ManageDepartmentUsersModal,
    },
    props: {
        isManager: {
            type: Boolean,
            default: false,
        },
        countries: {
            type: Array,
            default: () => [],
        },
        organisation: {
            type: Object,
            required: true,
        },
        membersPerDepartment: {
            type: Array,
            default: () => [],
        },
        organisationUsers: {
            type: Array,
            default: () => [],
        },
        organisationRoles: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit, root }) {
        const toBeDeleted = ref<number | null>(null);
        const showDeleteModal = ref(false);
        const showDepartmentModal = ref<boolean>(false);
        const selectedDepartment = ref<any>(null);
        const showManageDepartmentUsersModal = ref<boolean>(false);

        const sortedCountries = computed(() => props.countries.sort((a: any, b: any) => a.name.localeCompare(b.name)));

        const sortBy = ref<{ field: string; asc: boolean }>({ field: 'name', asc: true });

        const currentPage = ref<number>(1);
        const pageSize = 10;
        const { pagination, handleChangePage } = usePagination(root, {
            total: computed(() => props.organisation.departments.length),
            pageSize,
        });

        const columns = computed(() => {
            const tableColumns = [
                {
                    key: 'name',
                    label: 'Name',
                    type: ColumnType.String,
                    sortable: true,
                },
                {
                    key: 'address',
                    label: 'Address',
                    type: ColumnType.String,
                    sortable: true,
                },
                {
                    key: 'country',
                    label: 'Country',
                    type: ColumnType.String,
                    sortable: true,
                },
                {
                    key: 'city',
                    label: 'City',
                    type: ColumnType.String,
                    sortable: true,
                },
                {
                    key: 'members',
                    label: 'Members',
                    type: ColumnType.Integer,
                    position: ColumnPosition.Center,
                    sortable: true,
                },
            ];
            if (props.isManager)
                tableColumns.push({
                    key: 'actions',
                    label: 'Actions',
                    position: ColumnPosition.Right,
                } as any);
            return tableColumns;
        });

        const organisationLocations = computed(() =>
            props.organisation.departments
                .map((d: any) => d.location)
                .filter(
                    (value: any, index: number, self: any) => index === self.findIndex((t: any) => t.id === value.id),
                ),
        );

        const addDepartment = () => {
            selectedDepartment.value = null;
            showDepartmentModal.value = true;
        };

        const editDepartment = async (department: any) => {
            const country: any = sortedCountries.value.find((x: any) => Number(x.id) === department.countryId);
            const address = department.address.split(', ');
            selectedDepartment.value = {
                id: department.id,
                name: department.name,
                description: department.description,
                address: address.slice(0, address.length - 1).join(', '),
                postalCode: address[address.length - 1],
                cityId: department.cityId,
                countryId: department.countryId,
                continentId: country.continent.id,
                locationId: department.locationId,
            };
            showDepartmentModal.value = true;
        };

        const departments = computed(() =>
            props.organisation.departments.map((d: any) => {
                const counter: any = props.membersPerDepartment.find((dep: any) => dep.id === d.id);
                return {
                    id: d.id,
                    name: d.name,
                    description: d.description,
                    address: `${d.location.address}, ${d.location.postalCode}`,
                    city: d.location.city.name,
                    country: `${d.location.country.name} ${d.location.country.flag}`,
                    members: counter ? counter.members.length : 0,
                    cityId: Number(d.location.city.id),
                    countryId: Number(d.location.country.id),
                    locationId: Number(d.location.id),
                };
            }),
        );

        const sortedDepartments = computed(() =>
            R.sort(
                R[`${sortBy.value.asc ? 'ascend' : 'descend'}` as string](R.prop(sortBy.value.field)),
                departments.value,
            ),
        );

        const paginatedDepartments = computed(() =>
            sortedDepartments.value.slice((currentPage.value - 1) * pageSize, currentPage.value * pageSize),
        );

        const changePage = (newPage: number) => {
            currentPage.value = newPage;
            handleChangePage(newPage);
        };

        const changeSort = (newSort: { field: string; asc: boolean }) => {
            sortBy.value = newSort;
        };

        const removeDepartment = async () => {
            if (toBeDeleted.value) {
                try {
                    const { exec: execDelete } = useAxios(true);
                    await execDelete(
                        OrganizationsAPI.removeDepartment(props.organisation.id, toBeDeleted.value as any),
                    );

                    (root as any).$toastr.s('Department has been deleted successfuly', 'Success');
                    emit('refetch');
                    emit('refetchOrg');
                } catch (e) {
                    (root as any).$toastr.e(
                        'The department could not be deleted due to an error. Be sure that there are no users in the department.',
                        'Error',
                    );
                }

                showDeleteModal.value = false;
            }
        };

        const confirmDelete = (id: number) => {
            toBeDeleted.value = id;
            showDeleteModal.value = true;
        };

        const departmentMembers = computed(() => {
            if (!selectedDepartment.value) return [];
            const foundDepartment: any = props.membersPerDepartment.find(
                (item: any) => Number(item.id) === selectedDepartment.value.id,
            );
            if (foundDepartment) return foundDepartment.members;
            return [];
        });

        const manageDepartmentUsers = (departmentId: any) => {
            selectedDepartment.value = { id: Number(departmentId) };
            showManageDepartmentUsersModal.value = true;
        };

        return {
            selectedDepartment,
            sortedCountries,
            addDepartment,
            editDepartment,
            removeDepartment,
            showDeleteModal,
            confirmDelete,
            columns,
            paginatedDepartments,
            sortBy,
            pagination,
            changePage,
            changeSort,
            showDepartmentModal,
            organisationLocations,
            manageDepartmentUsers,
            showManageDepartmentUsersModal,
            departmentMembers,
        };
    },
});
