



































import { FormModal } from '@/app/components';
import store from '@/app/store';
import { useAxios } from '@/app/composable';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { clone } from 'ramda';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { OrganizationsAPI } from '../api';
import { OrganisationRole } from '../types';

export default defineComponent({
    name: 'RoleModal',
    components: {
        FormModal,
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        role: {
            type: Object,
        },
    },
    setup(props, { emit, root }) {
        const content = ref<any>(null);
        const isNew = ref<boolean>(true);
        const title = computed(() => (isNew.value ? 'Add Role' : 'Update Role'));
        const { exec } = useAxios(true);
        const user = computed(() => store.state.auth.user);
        const orgId = computed(() => user.value.organisationId);

        const updatingRole = ref<OrganisationRole>({
            id: 0,
            name: null,
            description: null,
        });

        if (props.role) {
            updatingRole.value = clone(props.role) as OrganisationRole;
            isNew.value = false;
        }

        const createRole = async () => {
            exec(OrganizationsAPI.createOrgRole(orgId.value, updatingRole.value))
                .then(() => {
                    emit('refetch');
                    emit('close');
                    (root as any).$toastr.s('Role has been created successfuly', 'Success');
                })
                .catch((e: any) => {
                    let message = 'Adding new role failed';
                    if (e.response?.status === 400 && e.response?.data?.message) message = e.response.data.message;
                    (root as any).$toastr.e(message, 'Error');
                });
        };

        const updateRole = async () => {
            exec(OrganizationsAPI.updateRole(orgId.value, updatingRole.value.id as number, updatingRole.value))
                .then(() => {
                    emit('refetch');
                    emit('close');
                    (root as any).$toastr.s('Role has been updated successfuly', 'Success');
                })
                .catch((e: any) => {
                    let message = 'Updating role failed';
                    if (e.response?.status === 400 && e.response?.data?.message) message = e.response.data.message;
                    (root as any).$toastr.e(message, 'Error');
                });
        };

        const validateAndProceed = async () => {
            const valid = await content.value.validate();
            if (valid) {
                if (isNew.value) await createRole();
                else await updateRole();
            }
        };

        return {
            content,
            title,
            validateAndProceed,
            updatingRole,
        };
    },
});
