









































































































import { Scrollbar, Tabs } from '@/app/components';
import { useAxios, useQuery, useResult } from '@/app/composable';
import store from '@/app/store';
import { maxLengthValidator, minLengthValidator } from '@/app/validators';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { extend } from 'vee-validate';
import { alpha_num, email, regex, required } from 'vee-validate/dist/rules';
import { OrganizationsAPI } from '../api';
import { OrganisationDepartments, OrganisationMembers, OrganisationOverview } from '../components';
import { useOrganisation } from '../composable';
import GET_COMPLETE_ORGANIZATION from '../graphql/getCompleteOrganization.graphql';

extend('required', {
    ...required,
    message: '{_field_} is required',
});
extend('email', email);
extend('regex', {
    ...regex,
    message: 'No special characters allowed',
});
extend('alpha_num', {
    ...alpha_num,
    message: 'Use only aplhanumeric characters',
});
extend('min', minLengthValidator);
extend('max', maxLengthValidator);

export default defineComponent({
    name: 'EditOrganization',
    metaInfo: {
        title: 'Organisation Details',
    },
    components: {
        OrbitSpinner,
        Tabs,
        Scrollbar,
        OrganisationDepartments,
        OrganisationMembers,
        OrganisationOverview,
    },
    setup(props, { root }) {
        const { exec, loading, error } = useAxios(true);
        const user = ref(store.state.auth.user);
        const isManager = ref(store.state.auth.user.roles.includes('manager'));
        const departments = ref([]);
        const activeTab = ref(0);

        const tabs = ref([
            { id: 0, title: 'Overview' },
            { id: 1, title: 'Departments' },
            { id: 2, title: 'Members' },
        ]);

        const { loading: organizationLoading, error: organizationError, result, refetch: refetchOrg } = useQuery(
            GET_COMPLETE_ORGANIZATION,
            { id: user.value.organisationId ? user.value.organisationId : 0 },
            { fetchPolicy: 'no-cache' },
        );

        const completeOrganization = useResult(result, null, (data: any) => data.organisation);

        const organizationUsers = computed(() => store.getters.organisation.users);
        const organizationDepartments = computed(() => store.getters.organisation.departments);

        const countries = computed(() => store.getters.locations.countries);

        const refetch = () => {
            useOrganisation(user.value.organisationId).initialize();
        };

        const organizationRoles = ref([]);
        const refetchRoles = () => {
            exec(OrganizationsAPI.retrieveRoles(user.value.organisationId))
                .then((res: any) => {
                    organizationRoles.value = res.data;
                })
                .catch(() => {
                    (root as any).$toastr.e('An error has occurred', 'Error');
                });
        };
        refetchRoles();

        const users = computed(() => {
            const orgUsers: any[] = [];
            if (organizationUsers.value && organizationDepartments.value) {
                organizationUsers.value.forEach((tmpUser: any) => {
                    const userDepartments: any[] = [];
                    organizationDepartments.value.forEach((dep: any) => {
                        dep.users.forEach((depUser: any) => {
                            if (depUser.user.id === tmpUser.id) {
                                const dUser = dep.users.find((u: any) => u.user.id === tmpUser.id);
                                userDepartments.push({
                                    id: dep.id,
                                    name: dep.name,
                                    role: dUser && dUser.role ? dUser.role.name : null,
                                    members: dep.users.length,
                                });
                            }
                        });
                    });
                    orgUsers.push({
                        ...tmpUser,
                        departments: userDepartments,
                    });
                });
            }
            return orgUsers;
        });

        const membersPerDepartment = computed(() => {
            if (organizationDepartments.value) {
                return organizationDepartments.value.map((d: any) => ({
                    id: d.id,
                    members: d.users.map((u: any) => ({
                        user: { id: u.user.id, firstName: u.user.firstName, lastName: u.user.lastName },
                        role: u.role ? { id: u.role.id, name: u.role.name } : null,
                    })),
                }));
            }
            return [];
        });

        const tabClicked = (idx: number) => {
            activeTab.value = idx;
        };

        return {
            tabs,
            activeTab,
            loading,
            organizationLoading,
            organizationError,
            error,
            departments,
            countries,
            completeOrganization,
            organizationDepartments,
            organizationRoles,
            tabClicked,
            user,
            isManager,
            refetchOrg,
            refetch,
            users,
            membersPerDepartment,
            refetchRoles,
        };
    },
});
