











































































































































































































import { ConfirmModal, SvgImage, TailwindTable } from '@/app/components';
import { useAxios, usePagination } from '@/app/composable';
import { ApplicationRoles, ColumnPosition, ColumnType, UserRoles } from '@/app/constants';
import store from '@/app/store';
import { Asset } from '@/modules/asset/types';
import { RetrievalQuery } from '@/modules/retrieval/interfaces';
import { Workflow } from '@/modules/workflow-designer/types';
import {
    ArrowCircleDownIcon,
    ArrowCircleUpIcon,
    BanIcon,
    CheckCircleIcon,
    MailOpenIcon,
    TrashIcon,
    UserAddIcon,
    XCircleIcon,
} from '@vue-hero-icons/outline';
import { computed, defineComponent, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { UsersAPI } from '../api';
import DerivedAssetsModal from '../components/DerivedAssetsModal.vue';
import { UserStatus } from '../constants';
import InviteUserModal from './InviteUserModal.vue';

export default defineComponent({
    name: 'OrganisationMembers',
    components: {
        TailwindTable,
        BanIcon,
        CheckCircleIcon,
        UserAddIcon,
        InviteUserModal,
        SvgImage,
        TrashIcon,
        DerivedAssetsModal,
        ArrowCircleUpIcon,
        ArrowCircleDownIcon,
        ConfirmModal,
        MailOpenIcon,
        XCircleIcon,
    },
    props: {
        isManager: {
            type: Boolean,
            default: false,
        },
        organisation: {
            type: Object,
            required: true,
        },
        organisationUsers: {
            type: Array,
            default: () => [],
        },
        organisationRoles: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { root, emit }) {
        const { loading, exec } = useAxios(true);
        const user = ref(store.state.auth.user);
        const showInviteUserModal = ref<boolean>(false);
        const updatingUserId = ref<number>(0);
        const roleConfirmationMessages = ref<any>({
            title: null,
            message: null,
        });
        const showRoleConfirmation = ref<boolean>(false);
        const roleAction = ref<Function | null>(null);
        const derivedAssets = ref<Asset[]>([]);
        const derivedWorkflows = ref<Workflow[]>([]);
        const derivedRetrievalQueries = ref<RetrievalQuery[]>([]);
        const deletedUserId = ref<number | null>();
        const showConfirmDeleteModal = ref(false);

        const applicationRoles: string[] = Object.values(ApplicationRoles);

        const appRoles = (roles: string[]) => {
            return roles.filter((role: string) => applicationRoles.includes(role) && role !== ApplicationRoles.User);
        };

        const orgRoles = (roles: string[]) => {
            return roles.filter((role: string) => !applicationRoles.includes(role));
        };

        const columns = computed(() => {
            const tableColumns = [
                {
                    key: 'id',
                    label: 'ID',
                    type: ColumnType.String,
                },
                {
                    key: 'name',
                    label: 'Name',
                    type: ColumnType.String,
                    sortable: true,
                },
                {
                    key: 'email',
                    label: 'Email',
                    type: ColumnType.String,
                    sortable: true,
                },
                {
                    key: 'roles',
                    label: 'Organisation Roles',
                    position: ColumnPosition.Center,
                },
                {
                    key: 'status',
                    label: 'Status',
                    position: ColumnPosition.Center,
                },
                {
                    key: 'departments',
                    label: 'Departments',
                    type: ColumnType.String,
                    position: ColumnPosition.Left,
                    sortable: true,
                },
            ];
            if (props.isManager)
                tableColumns.push({
                    key: 'actions',
                    label: 'Actions',
                    position: ColumnPosition.Right,
                });
            return tableColumns;
        });

        const sortBy = ref<{ field: string; asc: boolean }>({ field: 'name', asc: true });

        const currentPage = ref<number>(1);
        const pageSize = 10;
        const { pagination, handleChangePage } = usePagination(root, {
            total: computed(() => props.organisationUsers.length),
            pageSize,
        });

        const changePage = (newPage: number) => {
            currentPage.value = newPage;
            handleChangePage(newPage);
        };

        const changeSort = (newSort: { field: string; asc: boolean }) => {
            sortBy.value = newSort;
        };

        const users = computed(() =>
            props.organisationUsers.map((u: any) => ({
                id: u.id,
                name: `${u.firstName} ${u.lastName}`,
                email: u.email,
                status: u.status,
                departments: u.departments,
                roles: u.roles.reduce((acc: string[], role: string) => {
                    if (!u.roles.includes(UserRoles.Manager) || role !== UserRoles.Member) acc.push(role);
                    return acc;
                }, []),
            })),
        );

        const sortedUsers = computed(() => {
            if (sortBy.value.field === 'departments') {
                return R.sort((x: any, y: any) => {
                    const departmentA = x.departments.length ? x.departments[0].name : '-';
                    const departmentB = y.departments.length ? y.departments[0].name : '-';

                    if (sortBy.value.asc) return departmentA > departmentB ? 1 : -1;
                    return departmentA >= departmentB ? -1 : 1;
                }, users.value);
            }

            return R.sort(
                R[`${sortBy.value.asc ? 'ascend' : 'descend'}`](R.compose(R.toLower, R.prop(sortBy.value.field))),
                users.value,
            );
        });

        const paginatedUsers = computed(() =>
            sortedUsers.value.slice((currentPage.value - 1) * pageSize, currentPage.value * pageSize),
        );

        const activateUser = (id: number) => {
            exec(UsersAPI.activateMember(id))
                .then(() => {
                    emit('refetch');
                    (root as any).$toastr.s('Member has been activated successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Member could not be activated due to an error.', 'Error');
                });
        };

        const suspendUser = (id: number) => {
            exec(UsersAPI.disableMember(id))
                .then(() => {
                    emit('refetch');
                    (root as any).$toastr.s('Member has been deactivated successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Member could not be deactivated due to an error.', 'Error');
                });
        };

        const deactivateUserDry = (id: number) => {
            exec(UsersAPI.deactivateMemberDry(id))
                .then((response) => {
                    derivedAssets.value = response?.data.derivativeAssets;
                    derivedWorkflows.value = response?.data.derivativeWorkflows;
                    derivedRetrievalQueries.value = response?.data.derivativeRetrievalQueries;
                    deletedUserId.value = id;
                    showConfirmDeleteModal.value = true;
                })
                .catch(() => {
                    deletedUserId.value = null;
                    (root as any).$toastr.e('Member could not be deleted due to an error.', 'Error');
                });
        };

        const deactivateUser = () => {
            if (!deletedUserId.value) return;
            exec(UsersAPI.deactivateMember(deletedUserId.value))
                .then(() => {
                    emit('refetch');
                    (root as any).$toastr.s('Member has been deleted successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Member could not be deleted due to an error.', 'Error');
                })
                .finally(() => {
                    deletedUserId.value = null;
                    showConfirmDeleteModal.value = false;
                });
        };

        const resendInvitation = (id: number) => {
            exec(UsersAPI.resendInvitation(id))
                .then(() => {
                    emit('refetch');
                    (root as any).$toastr.s('Invitation has been resent', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to resend invitation.', 'Error');
                });
        };

        const cancelInvitation = (id: number) => {
            exec(UsersAPI.cancelInvitation(id))
                .then(() => {
                    emit('refetch');
                    (root as any).$toastr.s('Invitation has been cancelled', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to cancel invitation.', 'Error');
                });
        };

        const makeManager = () => {
            exec(UsersAPI.promote(updatingUserId.value))
                .then(() => {
                    emit('refetch');
                    showRoleConfirmation.value = false;
                    (root as any).$toastr.s(
                        'The user has been successfully promoted from simple organisation member to manager.',
                        'Success',
                    );
                })
                .catch(() => {
                    (root as any).$toastr.e('User could not be promoted due to an error.', 'Error');
                });
        };

        const makeMember = () => {
            exec(UsersAPI.demote(updatingUserId.value))
                .then(() => {
                    emit('refetch');
                    showRoleConfirmation.value = false;
                    (root as any).$toastr.s(
                        'The user has been successfully demoted from manager to simple organisation member.',
                        'Success',
                    );
                })
                .catch(() => {
                    (root as any).$toastr.e('User could not be demoted due to an error.', 'Error');
                });
        };

        const confirmRoleAction = (type: string, id: number) => {
            updatingUserId.value = id;
            if (type === 'manager') {
                roleAction.value = makeManager;
                roleConfirmationMessages.value.title = 'Promote Member to Manager';
                roleConfirmationMessages.value.message = 'Are you sure you want to promote this member to manager?';
            } else if (type === 'member') {
                roleAction.value = makeMember;
                roleConfirmationMessages.value.title = 'Demote Manager to Member';
                roleConfirmationMessages.value.message = 'Are you sure you want to demote this manager to member?';
            }
            showRoleConfirmation.value = true;
        };

        const cancelDelete = () => {
            derivedAssets.value = [];
            derivedWorkflows.value = [];
            derivedRetrievalQueries.value = [];
            showConfirmDeleteModal.value = false;
        };

        const rolesMapping = {
            [UserRoles.Admin]: 'bg-red-100 text-red-800',
            [UserRoles.Manager]: 'bg-green-100 text-green-800',
            [UserRoles.ModelModerator]: 'bg-blue-100 text-blue-800',
            [UserRoles.Member]: 'bg-neutral-200 text-neutral-800',
            [UserRoles.Guest]: 'bg-orange-100 text-orange-800',
        };

        const sortedRoles = (value: string[]) => {
            const clonedValue = R.clone(value);
            const map = Object.fromEntries(Object.keys(rolesMapping).map((k: string, idx: number) => [k, idx]));

            clonedValue.sort((a, b) => map[a] - map[b]);
            return clonedValue;
        };

        return {
            UserStatus,
            showInviteUserModal,
            user,
            columns,
            sortBy,
            pagination,
            loading,
            changePage,
            changeSort,
            paginatedUsers,
            activateUser,
            suspendUser,
            deactivateUser,
            deactivateUserDry,
            showConfirmDeleteModal,
            cancelDelete,
            derivedAssets,
            derivedWorkflows,
            derivedRetrievalQueries,
            sortedRoles,
            rolesMapping,
            confirmRoleAction,
            roleConfirmationMessages,
            showRoleConfirmation,
            roleAction,
            resendInvitation,
            cancelInvitation,
            UserRoles,
            appRoles,
            orgRoles,
        };
    },
});
