










































































































import { FormModal, TwButton } from '@/app/components';
import { useAxios } from '@/app/composable';
import { CheckIcon, PlusIcon, TrashIcon, XIcon } from '@vue-hero-icons/outline';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import * as R from 'ramda';
import Multiselect from 'vue-multiselect';
import { DepartmentsAPI } from '../api';
import { DepartmentMember } from '../interfaces/department-member.interface';
import { DepartmentUser } from '../types';

export default defineComponent({
    name: 'ManageDepartmentUsersModal',
    components: {
        FormModal,
        CheckIcon,
        XIcon,
        PlusIcon,
        TrashIcon,
        TwButton,
        Multiselect,
    },
    props: {
        departmentId: {
            type: Number,
            required: true,
        },
        organisationUsers: {
            type: Array,
            default: () => [],
        },
        departmentMembers: {
            type: Array as PropType<DepartmentMember[]>,
            default: () => [],
        },
        organisationRoles: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit, root }) {
        const { exec, loading } = useAxios(true);
        const addingUser = ref<boolean>(false);

        const newDepartmentUser = ref<any>({
            user: null,
            role: null,
        });

        const resetNewDepartmentMember = () => {
            newDepartmentUser.value.user = null;
            newDepartmentUser.value.role = null;
            addingUser.value = false;
        };

        const departmentUserIds = computed(() => props.departmentMembers.map((user: any) => user.user.id));

        const usersNotInDepartment = computed(() =>
            R.sortWith([R.ascend(R.prop('label'))])(
                props.organisationUsers
                    .filter((user: any) => !departmentUserIds.value.includes(user.id))
                    .map((u: any) => ({ label: `${u.firstName} ${u.lastName}`, id: u.id })),
            ),
        );

        const sortedOrganisationRoles = computed(() =>
            R.sortWith([R.ascend(R.prop('label'))])(props.organisationRoles as any),
        );

        const saveUser = () => {
            const payload: DepartmentUser = {
                userId: Number(newDepartmentUser.value.user.id),
                roleId: newDepartmentUser.value.role ? Number(newDepartmentUser.value.role.id) : null,
            };
            exec(DepartmentsAPI.addUser(props.departmentId, payload))
                .then(() => {
                    emit('refetch');
                    emit('refetch-org');
                    resetNewDepartmentMember();
                    (root as any).$toastr.s('User added to department', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to add user to department.', 'Error');
                });
        };

        const removeUserFromDepartment = (userId: number) => {
            exec(DepartmentsAPI.removeUser(props.departmentId, Number(userId)))
                .then(() => {
                    emit('refetch');
                    emit('refetch-org');
                    (root as any).$toastr.s('User removed from the department', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Failed to remove user from department.', 'Error');
                });
        };

        return {
            addingUser,
            removeUserFromDepartment,
            saveUser,
            newDepartmentUser,
            usersNotInDepartment,
            resetNewDepartmentMember,
            loading,
            sortedOrganisationRoles,
        };
    },
});
