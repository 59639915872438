





















































import { FormModal } from '@/app/components';
import { useAxios } from '@/app/composable';
import { emailValidator, maxLengthValidator, minLengthValidator } from '@/app/validators';
import { defineComponent, ref } from '@vue/composition-api';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { KeycloakAPI } from '../api';

extend('email', {
    ...emailValidator,
    message: 'Email is not valid',
});
extend('min', minLengthValidator);
extend('max', maxLengthValidator);

export default defineComponent({
    name: 'InviteUserModal',
    components: {
        FormModal,
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        departments: {
            type: Array,
            default: () => [],
        },
        roles: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit, root }) {
        const content = ref<any>(null);
        const { exec, loading } = useAxios(true);

        const userInvitation = ref({
            firstName: '',
            lastName: '',
            email: '',
            username: '', // will be temporarily identical with email
        });

        const sendInvitation = async () => {
            userInvitation.value.username = userInvitation.value.email;
            await exec(KeycloakAPI.inviteUser(userInvitation.value))
                .then(() => {
                    emit('close');
                    emit('refetch');
                    (root as any).$toastr.s('Member has been invited successfuly', 'Success');
                })
                .catch((e) => {
                    if (e && e.response && e.response.status === 409) {
                        (root as any).$toastr.e('Member with this email has already been invited', 'Error');
                    } else {
                        (root as any).$toastr.e('The member could not be invited due to an error', 'Error');
                    }
                });
        };

        const validateAndProceed = async () => {
            const valid = await content.value.validate();
            if (valid) {
                sendInvitation();
            }
        };

        return {
            content,
            validateAndProceed,
            userInvitation,
            loading,
        };
    },
});
