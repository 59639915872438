












































































































































import { FormModal } from '@/app/components';
import { useAxios } from '@/app/composable';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { clone } from 'ramda';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { DepartmentsAPI, OrganizationsAPI } from '../api';
import { CreateDepartment } from '../types';
import CitySelect from './CitySelect.vue';

export default defineComponent({
    name: 'DepartmentModal',
    components: {
        FormModal,
        ValidationObserver,
        ValidationProvider,
        CitySelect,
    },
    props: {
        department: {
            type: Object,
        },
        sortedCountries: {
            type: Array,
            default: () => [],
        },
        cities: {
            type: Array,
            default: () => [],
        },
        organisationId: {
            type: Number,
            required: true,
        },
        locations: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit, root }) {
        const content = ref<any>(null);
        const { exec } = useAxios(true);
        const isNew = ref<boolean>(true);
        const selectedLocation = ref<number | null>(null);

        const title = computed(() => (isNew.value ? 'Add Department' : 'Update Department'));

        const dep = ref<CreateDepartment>({
            id: 0,
            name: '',
            description: '',
            address: '',
            postalCode: '',
            countryId: null,
            cityId: null,
            continentId: 0,
            locationId: 0,
        });

        if (props.department) {
            dep.value = clone(props.department) as CreateDepartment;
            selectedLocation.value = props.department.locationId;
            isNew.value = false;
        }

        const updateDepartment = async () => {
            const departmentPayload = {
                name: dep.value.name,
                description: dep.value.description,
            };
            const country: any = props.sortedCountries.find((x: any) => x.id === dep.value.countryId);
            if (country) dep.value.continentId = country.continent.id;
            const departmentLocationPayload = {
                address: dep.value.address,
                postalCode: dep.value.postalCode,
                countryId: dep.value.countryId,
                cityId: dep.value.cityId,
                continentId: dep.value.continentId,
            };

            exec(DepartmentsAPI.updateDepartment(dep.value.id as number, departmentPayload as any))
                .then(() => {
                    exec(
                        DepartmentsAPI.updateDepartmentLocation(
                            dep.value.id as number,
                            departmentLocationPayload as any,
                        ),
                    ).then(() => {
                        emit('refetch');
                        emit('close');
                        (root as any).$toastr.s('Department details have been updated successfuly', 'Success');
                    });
                })
                .catch((e) => {
                    let message = 'The department could not be updated due to an error.';
                    if (e.response?.status === 400 && e.response?.data?.message) message = e.response.data.message;
                    (root as any).$toastr.e(message, 'Error');
                });
        };

        const createDepartment = () => {
            if (selectedLocation.value) {
                const foundLocation: any = props.locations.find((l: any) => l.id === selectedLocation.value);
                dep.value.address = foundLocation.address;
                dep.value.postalCode = foundLocation.postalCode;
                dep.value.cityId = foundLocation.city.id;
                dep.value.countryId = foundLocation.country.id;
            }
            const country: any = props.sortedCountries.find((x: any) => x.id === dep.value.countryId);
            dep.value.continentId = country.continent.id;
            exec(OrganizationsAPI.createDepartment(props.organisationId, dep.value as any))
                .then(() => {
                    emit('refetch');
                    emit('close');
                    (root as any).$toastr.s('Department has been created successfuly', 'Success');
                })
                .catch((e) => {
                    let message = 'The department could not be created due to an error.';
                    if (e.response?.status === 400 && e.response?.data?.message) message = e.response.data.message;
                    (root as any).$toastr.e(message, 'Error');
                });
        };

        const validateAndProceed = async () => {
            const valid = await content.value.validate();
            if (valid) {
                if (isNew.value) await createDepartment();
                else await updateDepartment();
            }
        };

        const selectLocation = (event: any) => {
            if (!event.target.value) {
                if (isNew.value) {
                    dep.value.address = null;
                    dep.value.postalCode = null;
                    dep.value.countryId = null;
                    dep.value.cityId = null;
                }
            } else {
                const location: any = props.locations.find((loc: any) => loc.id === event.target.value);
                if (location) {
                    dep.value.address = location.address;
                    dep.value.postalCode = location.postalCode;
                    dep.value.countryId = location.country.id;
                    dep.value.cityId = location.city.id;
                }
            }
        };

        const formatLocation = (location: any) =>
            `${location.address.length > 20 ? location.address.slice(0, 20) + '...' : location.address}, ${
                location.postalCode
            } ${location.city.name}, ${location.country.name}`;

        const changeCity = (cityId: number | null) => {
            dep.value.cityId = cityId;
        };

        return {
            content,
            selectedLocation,
            title,
            dep,
            validateAndProceed,
            selectLocation,
            isNew,
            formatLocation,
            changeCity,
        };
    },
});
